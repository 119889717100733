<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">Beneficiary List</div>
        <div class="
          col-lg-6 col-12
          text-end
          d-flex
          justify-content-end
          custom-flex-cloumn-mob
        ">
        <button type="button" @click="backToSchemeListRedirect()" class="header-btn-outer btn btn-primary">
                Back To Scheme
            </button>
        </div>
    </div>
    <div class="company-section-outer">
        <div class="layout-content-section">
            <div class="custom-ultima-datatable custom-voter-datable" style="height: calc(100vh - 141px)">
                <DataTable :value="messageList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex"
                    :paginator="true" :rows="30" :totalRecords="totalRecords" @page="changePage($event)"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    :lazy="true" dataKey="lk1" v-model:selection="selectedvoter" :loading="loading">
                    <template #empty>No Records found.</template>
                    <template #loading>Loading data. Please wait...</template>
                    <Column headerStyle="width: 3%" bodyStyle="width: 3%" class="justify-content-center"
                        selectionMode="multiple"></Column>
                    <Column header="Select All" headerStyle="width: 96%" bodyStyle="width: 96%">
                        <template #body="{ data }">
                            <div class="company-detail-box-outer mb-3">
                                <div class="row g-2">
                                    <div class="
                            col-lg-2 col-md-2 col-12
                            voter-profile-col
                            d-flex
                            align-items-center
                          ">
                                        <div class="customer-label-group">
                                            <div class="d-flex align-items-center">
                                                <div>
                                                    <img v-if="data.lk28 == null || data.lk28 == ''"
                                                        src="/assets/images/avatar.png" class="
                                    rounded
                                    bg-light
                                    me-auto
                                    d-block
                                    voter-photo-img
                                  " alt="" />
                                                    <img v-if="data.lk28 != null && data.lk28 != ''" :src="data.lk28" class="
                                    rounded
                                    bg-light
                                    me-auto
                                    d-block
                                    voter-photo-img
                                  " alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-12 voter-name-col">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Citizen Name</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{ data.lk17 ? data.lk17 : "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Gender | Blood Group</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{
                                                            data.lk24
                                                            ? data.lk24 == 1
                                                                ? "Male"
                                                                : data.lk24 == 2
                                                                    ? "Female"
                                                                    : "Others"
                                                            : "N/A"
                                                        }}
                                                        | {{ data.lk20 ? data.lk20 : "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Vidhan Sabha</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{ data.lk38 ? data.lk38 : "N/A" }}
                                                        <span v-if="data.lk39">({{ data.lk39 }})</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-12">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Date of Birth | Age</label>
                                                    <div class="from-label-value">
                                                        {{ data.lk21 ? data.lk21 : "N/A" }} |
                                                        {{ data.lk22 ? data.lk22 : "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Voter Id | Part Number</label>
                                                    <div class="from-label-value">
                                                        {{ data.lk26 ? data.lk26 : "N/A" }} |
                                                        {{ data.lk27 ? data.lk27 : "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Lok Sabha</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{ data.lk40 ? data.lk40 : "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-12">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Profession | Designation</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{ data.lk30 ? data.lk30 : "N/A" }} |
                                                        {{ data.lk36 ? data.lk36 : "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Area | Pin Code</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{ data.lk18 ? data.lk18 : "N/A" }} |
                                                        {{ data.lk19 ? data.lk19 : "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Specification</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{ data.lk37 ? data.lk37 : "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-12">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Email</label>
                                                    <div class="from-label-value">
                                                        {{ data.lk23 ? data.lk23 : "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="
                                  customer-label-group
                                  mb-2
                                  d-flex
                                  justify-content-between
                                ">
                                                    <div>
                                                        <label class="form-label">Mobile</label>
                                                        <div class="from-label-value">
                                                            +{{ data.mobnumber }}
                                                            <!-- +91-{{ data.first3digit }}XXXX{{
                                    data.last3digit
                                  }} -->
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-end">
                                                        <button class="btn custom-outline-call-btn mx-2"
                                                            @click="calltriggertovoter(data.lk3)"
                                                            :disabled="callstatus == 1">
                                                            <i class="fas fa-phone-alt call-btn-color"></i></button>
                                                        <button
                                                            @click="whatsappHistoryModalOpen(data.lk1, data.lk17, data.lk3, data.lk28)"
                                                            class="btn custom-outline-whatsapp-btn me-2">
                                                            <i class="fab fa-whatsapp whatsapp-color"></i>
                                                        </button>
                                                        <button type="button" title="View Details"
                                                            class="btn custom-outline-view-btn me-2" @click="customerdetailModalOpen(data.lk1, data.lk3)
                                                                ">
                                                            <i class="fas fa-plus call-btn-color"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Reg. Date & Time</label>
                                                    <div class="from-label-value">
                                                        {{
                                                            format_date_time(data.datetime)
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
    <div class="modal-mask" v-if="hierarchymodalstatus">
    <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Family Hierarchy</h5>
          <button type="button" class="btn-close" @click="closerhierarchymodal()"></button>
        </div>
        <OrganizationChart />
      </div>
    </div>
  </div>
  <div class="modal-mask" v-if="engagementmodalstatus">
    <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Engagements</h5>
          <button type="button" class="btn-close" @click="closevoterengagementmodal()"></button>
        </div>
        <EngagementTotalVoterModal />
      </div>
    </div>
  </div>
  <div class="modal-mask" v-if="maintenermodalstatus">
    <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Influencers</h5>
          <button type="button" class="btn-close" @click="closevotermaintenermodal()"></button>
        </div>
        <MaintenerTotalVoterModal />
      </div>
    </div>
  </div>
  <!-- filter start here -->
  <div class="modal-mask" v-if="voterfiltermodalsflag">
    <div class="
          modal-md modal-dialog-scrollable modal-fullscreen
          ms-auto
          custom-modal-outer
        ">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Filter By Citizens</h5>
          <button type="button" class="btn-close" @click="voterFilterModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Citizen Name</label>
                    <input type="text" v-model="lk17" class="form-control" placeholder="Enter Citizen Name" maxlength="30"
                      @keypress="isLetterWithSpace($event)" autocomplete="off" />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Mobile Number</label>
                    <input type="text" v-model="lk3" class="form-control" placeholder="Enter Mobile Number" maxlength="10"
                      @keypress="onlyNumber" autocomplete="off" />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Email Id</label>
                    <input type="text" v-model="lk23" class="form-control" placeholder="Enter Email Id" maxlength="30"
                      autocomplete="off" />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Profession</label>
                    <Multiselect v-model="lk25" :options="voterprofessionList" :searchable="true" label="label"
                      placeholder="Select Profession" class="multiselect-custom text-capitalize" :canClear="true"
                      :closeOnSelect="true" />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Blood Group</label>
                    <input type="text" v-model="lk20" class="form-control" placeholder="Enter Blood Group" maxlength="6"
                      autocomplete="off" />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Gender</label>
                    <div class="custom-group-radio-box-btn">
                      <input type="radio" class="btn-check" v-model="lk24" name="gender-option" id="male"
                        autocomplete="off" value="1" />
                      <label class="btn custom-radio-box-btn text-capitalize" for="male">Male</label>
                      <input type="radio" class="btn-check" v-model="lk24" name="gender-option" id="female"
                        autocomplete="off" value="2" />
                      <label class="btn custom-radio-box-btn text-capitalize" for="female">Female</label>
                      <input type="radio" class="btn-check" v-model="lk24" name="gender-option" id="other"
                        autocomplete="off" value="3" />
                      <label class="btn text-capitalize" for="other">Other</label>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Age</label>
                    <input type="text" v-model="lk22" class="form-control" placeholder="Enter Age" maxlength="2"
                      @keypress="onlyNumber" autocomplete="off" />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Pin Code</label>
                    <input type="text" v-model="lk19" class="form-control" placeholder="Enter Age" maxlength="6"
                      @keypress="onlyNumber" autocomplete="off" />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Area</label>
                    <input type="text" v-model="lk18" class="form-control" placeholder="Enter Area" maxlength="20"
                      autocomplete="off" />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Voter Id</label>
                    <input type="text" v-model="lk26" class="form-control" placeholder="Enter Booth No" maxlength="10"
                      autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 m-0">
            <div class="d-flex justify-content-between">
              <button class="btn filter-apply-btn" @click="btnFilterSearch(
                    lk17,
                    lk3,
                    lk23,
                    lk25,
                    lk20,
                    lk24,
                    lk22,
                    lk19,
                    lk18,
                    lk26
                  )
                  ">
                Apply Filter
              </button>
              <button class="btn btn-link filter-reset-btn" @click="resetVoterFilter">
                Reset All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- filter end here -->
  <!-- next action modal start here -->
  <div class="modal-mask" v-if="viewvoterdetailstatus">
    <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header custom-custmer-header-border">
          <h5 class="modal-title" id="staticBackdropLabel">Citizen Details</h5>
          <button type="button" class="btn-close" @click="voterDetailModalClose()"></button>
        </div>
        <ul class="nav nav-tabs custom-customer-modal-tab" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="voter-aadharcard-tab" data-bs-toggle="tab"
              data-bs-target="#voteraadhardetails" type="button" role="tab" aria-controls="vehicle-details"
              aria-selected="true" @click="getaadhardetails()">
              Aadhaar
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="voter-idcard-tab" data-bs-toggle="tab" data-bs-target="#votercarddetails"
              type="button" role="tab" aria-controls="appointment-history" aria-selected="false"
              @click="getvotertabdetails()">
              Voter Id
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="voter-pancard-tab" data-bs-toggle="tab" data-bs-target="#voterpandetails"
              type="button" role="tab" aria-controls="customer-details" aria-selected="false" @click="getpandetails()">
              PAN
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="voter-driving-tab" data-bs-toggle="tab" data-bs-target="#voterdrivingdetails"
              type="button" role="tab" aria-controls="contact-history" aria-selected="false" @click="getlicensedetails">
              Driving Licence
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="voter-schemes-tab" data-bs-toggle="tab" data-bs-target="#voterschemesdetails"
              type="button" role="tab" aria-controls="customer-details" aria-selected="false"
              @click="selectedtab((e = 1))">
              Schemes
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="voter-history-tab" data-bs-toggle="tab" data-bs-target="#voterhistorydetails"
              type="button" role="tab" aria-controls="customer-details" aria-selected="false"
              @click="selectedtab((e = 2))">
              History
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="voter-hierarchy-tab" data-bs-toggle="tab" data-bs-target="#voterhierachydetails"
              type="button" role="tab" aria-controls="contact-history" aria-selected="false"
              @click="selectedtab((e = 5))">
              Family Hierarchy
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="voter-engagements-tab" data-bs-toggle="tab"
              data-bs-target="#voterengagementsdetails" type="button" role="tab" aria-controls="contact-history"
              aria-selected="false" @click="selectedtab((e = 6))">
              Engagements
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="voter-influencers-tab" data-bs-toggle="tab"
              data-bs-target="#voterinfluencersdetails" type="button" role="tab" aria-controls="contact-history"
              aria-selected="false" @click="selectedtab((e = 7))">
              Influencers
            </button>
          </li>
        </ul>
        <div class="modal-body modal-body-scroll-outer postion-relative">
          <div class="tab-content" id="myTabContent" v-if="!showmodalloader">
            <div class="tab-pane fade show active" id="voteraadhardetails" role="tabpanel"
              aria-labelledby="voter-aadharcard-tab">
              <div>
                <div v-if="aadhardivdisplayflag == 1">
                  <div class="row">
                    <div class="col-lg-2 col-md-2 col-12 d-flex align-items-center">
                      <div class="customer-label-group">
                        <div class="d-flex align-items-center">
                          <div>
                            <img v-if="AadharTabDetails.aa11 == null ||
                                AadharTabDetails.aa11 == ''
                                " src="/assets/images/avatar.png" class="rounded bg-light me-auto d-block" alt=""
                              width="90" />
                            <img :src="this.AadharTabDetails.aa11" class="rounded bg-light me-auto d-block" alt=""
                              width="90" v-if="AadharTabDetails.aa11 != null ||
                                  AadharTabDetails.aa11 != ''
                                  " />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Name</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                AadharTabDetails.aa4
                                ? AadharTabDetails.aa4
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Aadhaar Number</label>
                            <div class="from-label-value">
                              {{
                                AadharTabDetails.aa3
                                ? AadharTabDetails.aa3
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Father's Name</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                AadharTabDetails.aa5
                                ? AadharTabDetails.aa5
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Date of Birth</label>
                        <div class="from-label-value">
                          {{
                            AadharTabDetails.aa8 ? AadharTabDetails.aa8 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Gender</label>
                        <div class="from-label-value">
                          {{
                            AadharTabDetails.aa17
                            ? AadharTabDetails.aa17 == 1
                              ? "Male"
                              : AadharTabDetails.aa17 == 2
                                ? "Female"
                                : "Others"
                            : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">House</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.aa24
                            ? AadharTabDetails.aa24
                            : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Landmark</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.aa25
                            ? AadharTabDetails.aa25
                            : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Street</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.aa23
                            ? AadharTabDetails.aa23
                            : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Location</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.aa17
                            ? AadharTabDetails.aa17
                            : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Post Office</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.aa15
                            ? AadharTabDetails.aa15
                            : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Area</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.aa19
                            ? AadharTabDetails.aa19
                            : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">City</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.aa16
                            ? AadharTabDetails.aa16
                            : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Sub Dist</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.aa22
                            ? AadharTabDetails.aa22
                            : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">State</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.aa14
                            ? AadharTabDetails.aa14
                            : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-8 col-md-8 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Address</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.aa6 ? AadharTabDetails.aa6 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Pincode</label>
                        <div class="from-label-value">
                          {{
                            AadharTabDetails.aa7 ? AadharTabDetails.aa7 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="aadhardivdisplayflag == 0">
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="custom-form-group">
                            <label for="formaadharcardinput" class="form-label">Aadhaar No
                              <span class="text-danger">*</span></label>
                            <input type="text" v-model="aadharcardnumber" class="form-control text-uppercase"
                              id="formaadharcardinput" placeholder="Enter Aadhaar No" autocomplete="off" maxlength="12"
                              @keypress="onlyNumber" :disabled="displayMobilePopUp" />
                            <span class="custom-error position-fixed" v-if="aadharerr">
                              {{ aadharerr }}
                            </span>
                          </div>
                        </div>
                        <div class="
                              col-lg-2 col-md-2 col-12
                              d-flex
                              justify-content-start
                              align-items-end
                            " v-if="!displayMobilePopUp">
                          <div class="custom-form-group">
                            <button type="button" class="header-btn-outer btn btn-primary" :style="{ width: '100px' }"
                              @click="sendAadharOtp()" :disabled="aadharcardnumber.length != 12">
                              <span v-if="!showloader">Send OTP</span>
                              <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-loader-btn
                                  " role="status" v-if="showloader">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-12" v-if="displayMobilePopUp">
                          <div class="custom-form-group">
                            <label for="formaadharcardinput" class="form-label">OTP <span
                                class="text-danger">*</span></label>
                            <input type="text" v-model="aadharotp" class="form-control otp-input-space"
                              id="formaadharcardinput" placeholder="Enter OTP" autocomplete="off" maxlength="6"
                              minlength="6" @keypress="onlyNumber" :disabled="mobile_otp_status == 1" />
                            <!-- <span class="custom-error" v-if="v$.mobileotp.$errors">
                  {{ v$.mobileotp.$errors.$message }}
                </span> -->
                            <span class="custom-error" v-if="mobileotperr">
                              {{ mobileotperr }}
                            </span>
                            <span v-if="timerMobileStatus" class="otp-counter-text position-fixed mt-2">{{
                              timerMobileInterval }}</span>
                          </div>
                        </div>
                        <div class="
                              col-lg-2 col-md-2 col-12
                              d-flex
                              justify-content-start
                              align-items-end
                            " v-if="displayMobilePopUp">
                          <div class="custom-form-group">
                            <button type="button" class="header-btn-outer btn btn-success" @click="verifyAadharOtp()"
                              :style="{ width: '80px' }" :disabled="aadharotp.length != 6">
                              <span v-if="!showloader">Verify</span>
                              <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-loader-btn
                                  " role="status" v-if="showloader">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="votercarddetails" role="tabpanel" aria-labelledby="voter-idcard-tab">
              <div>
                <div v-if="voterdivdisplayflag == 1">
                  <div class="d-flex align-items-start">
                    <ul class="nav nav-tabs custom-vertical-modal-tab flex-column me-3" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation" v-if="this.voterTabDetails != ''">
                        <button class="nav-link active" id="voter-idcard-first-tab" data-bs-toggle="tab"
                          data-bs-target="#votercarddetailsfirst" type="button" role="tab"
                          aria-controls="appointment-history-first" aria-selected="true" @click="getvoterdetails()">
                          <span class="d-flex justify-content-between align-items-center"><span>{{ voterTabDetails.ab3
                          }}</span>
                            <span class="user-badge status-active"><i
                                class="fas fa-check-circle text-success me-1"></i>Primary</span></span>
                        </button>
                      </li>
                      <li class="nav-item" role="presentation" v-if="this.secVoterTabDetails != ''">
                        <button class="nav-link" id="voter-aadharcard-second-tab" data-bs-toggle="tab"
                          data-bs-target="#voteraadhardetailssecond" type="button" role="tab"
                          aria-controls="vehicle-details-second" aria-selected="false" @click="getsecvoterdetails()">
                          <span class="d-flex justify-content-between align-items-center"><span>{{ secVoterTabDetails.ab3
                          }}</span></span>
                        </button>
                      </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                      <div class="tab-pane fade show active" id="votercarddetailsfirst" role="tabpanel"
                        aria-labelledby="voter-idcard-first-tab">
                        <div class="row">
                          <div class="
                                              col-lg-12 col-12
                                              text-end
                                              d-flex
                                              justify-content-end align-items-center
                                            ">
                            <label class="from-switch-label">Primary Voter Id</label>
                            <i class="fas fa-check-circle text-success fa-2x"></i>
                          </div>
                        </div>
                        <Divider />
                        <div class="row">
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Voter Number</label>
                              <div class="from-label-value">
                                {{
                                  voterTabDetails.ab3 ? voterTabDetails.ab3 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Name</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  voterTabDetails.ab6 ? voterTabDetails.ab6 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Last Updated</label>
                              <div class="from-label-value">
                                {{ format_date(voterTabDetails.ab12) }} <span
                                  v-if="voterTabDetails.ab12 == '' || voterTabDetails.ab12 == null">N/A</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Part Name</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  voterTabDetails.ab4 ? voterTabDetails.ab4 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Part Number</label>
                              <div class="from-label-value">
                                {{
                                  voterTabDetails.ab5 ? voterTabDetails.ab5 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Polling Station</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  voterTabDetails.ab7 ? voterTabDetails.ab7 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Age</label>
                              <div class="from-label-value">
                                {{
                                  voterTabDetails.ab8 ? voterTabDetails.ab8 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Gender</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  voterTabDetails.ab13
                                  ? voterTabDetails.ab13 == 1
                                    ? "Male"
                                    : voterTabDetails.ab13 == 2
                                      ? "Female"
                                      : "Others"
                                  : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Area</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  voterTabDetails.ab18 ? voterTabDetails.ab18 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">City</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  voterTabDetails.ab16 ? voterTabDetails.ab16 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">State</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  voterTabDetails.ab14 ? voterTabDetails.ab14 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Pincode</label>
                              <div class="from-label-value">
                                {{
                                  voterTabDetails.ab20 ? voterTabDetails.ab20 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Assembly Constituency</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  voterTabDetails.ab22 ? voterTabDetails.ab22 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Assembly Constituency Number</label>
                              <div class="from-label-value">
                                {{
                                  voterTabDetails.ab23 ? voterTabDetails.ab23 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Parliamentary Constituency</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  voterTabDetails.ab24 ? voterTabDetails.ab24 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="voteraadhardetailssecond" role="tabpanel"
                        aria-labelledby="voter-aadharcard-second-tab">
                        <div class="row">
                          <div class="
                                              col-lg-12 col-12
                                              text-end
                                              d-flex
                                              justify-content-end align-items-center
                                            ">
                            <label class="from-switch-label">Set as Primary Voter Id</label>
                            <InputSwitch v-model="VoterSetDefaultSwitch"
                              @change="switchVoterCardStatus(secVoterTabDetails)" />
                          </div>
                        </div>
                        <Divider />
                        <div class="row">
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Voter Number</label>
                              <div class="from-label-value">
                                {{
                                  secVoterTabDetails.ab3 ? secVoterTabDetails.ab3 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Name</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  secVoterTabDetails.ab6 ? secVoterTabDetails.ab6 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Last Updated</label>
                              <div class="from-label-value">
                                {{
                                  secVoterTabDetails.ab12 ? secVoterTabDetails.ab12 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Part Name</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  secVoterTabDetails.ab4 ? secVoterTabDetails.ab4 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Part Number</label>
                              <div class="from-label-value">
                                {{
                                  secVoterTabDetails.ab5 ? secVoterTabDetails.ab5 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Polling Station</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  secVoterTabDetails.ab7 ? secVoterTabDetails.ab7 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Age</label>
                              <div class="from-label-value">
                                {{
                                  secVoterTabDetails.ab8 ? secVoterTabDetails.ab8 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Gender</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  secVoterTabDetails.ab13
                                  ? secVoterTabDetails.ab13 == 1
                                    ? "Male"
                                    : secVoterTabDetails.ab13 == 2
                                      ? "Female"
                                      : "Others"
                                  : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Area</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  secVoterTabDetails.ab18 ? secVoterTabDetails.ab18 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">City</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  secVoterTabDetails.ab16 ? secVoterTabDetails.ab16 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">State</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  secVoterTabDetails.ab14 ? secVoterTabDetails.ab14 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Pincode</label>
                              <div class="from-label-value">
                                {{
                                  secVoterTabDetails.ab20 ? secVoterTabDetails.ab20 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Assembly Constituency</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  secVoterTabDetails.ab22 ? secVoterTabDetails.ab22 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Assembly Constituency Number</label>
                              <div class="from-label-value">
                                {{
                                  secVoterTabDetails.ab23 ? secVoterTabDetails.ab23 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Parliamentary Constituency</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  secVoterTabDetails.ab24 ? secVoterTabDetails.ab24 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="voterdivdisplayflag == 0">
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="custom-form-group">
                            <label for="formLastNameInput" class="form-label">Voter Id
                              <span class="text-danger">*</span></label>
                            <input type="text" v-model="votercardnumber" class="form-control text-uppercase"
                              id="formLastNameInput" placeholder="Enter Voter Id" autocomplete="off" maxlength="10" />
                            <span class="custom-error position-fixed" v-if="voteriderr">
                              {{ voteriderr }}
                            </span>
                          </div>
                        </div>
                        <div class="
                              col-lg-4 col-md-4 col-12
                              d-flex
                              justify-content-start
                              align-items-end
                            ">
                          <div class="custom-form-group">
                            <button type="button" class="header-btn-outer btn btn-primary" @click="fetchvoteriddetails()"
                              :disabled="errStatus || votercardnumber.length == 0
                                " :style="{ width: '104px' }">
                              <span v-if="!showloader">Get Details</span>
                              <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-loader-btn
                                  " role="status" v-if="showloader">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="voterpandetails" role="tabpanel" aria-labelledby="voter-pancard-tab">
              <div>
                <div v-if="pandivdisplayflag == 1">
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Pan Number</label>
                        <div class="from-label-value">
                          {{ PanTabDetails.ad3 ? PanTabDetails.ad3 : "N/A" }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Name</label>
                        <div class="from-label-value">
                          {{ PanTabDetails.ad4 ? PanTabDetails.ad4 : "N/A" }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Category</label>
                        <div class="from-label-value">
                          {{
                            PanTabDetails.ad5 == 1
                            ? "Person"
                            : PanTabDetails.ad5 == 2
                              ? "Business"
                              : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="pandivdisplayflag == 0">
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="custom-form-group">
                            <label for="formpancardinput" class="form-label">Pan No <span
                                class="text-danger">*</span></label>
                            <input type="text" v-model="pancardnumber" class="form-control text-uppercase"
                              id="formpancardinput" placeholder="Enter Pan Card No" autocomplete="off" maxlength="10" />
                            <span class="custom-error position-fixed" v-if="errpan">
                              {{ errpan }}
                            </span>
                            <span class="custom-error position-fixed" v-if="errmsg">
                              {{ errmsg }}
                            </span>
                          </div>
                        </div>
                        <div class="
                              col-lg-4 col-md-4 col-12
                              d-flex
                              justify-content-start
                              align-items-end
                            ">
                          <div class="custom-form-group">
                            <button type="button" class="header-btn-outer btn btn-primary" @click="getpancarddetails()"
                              :disabled="errStatus || pancardnumber.length == 0" :style="{ width: '104px' }">
                              <span v-if="!showloader">Get Details</span>
                              <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-loader-btn
                                  " role="status" v-if="showloader">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="voterdrivingdetails" role="tabpanel" aria-labelledby="voter-driving-tab">
              <EditDrivingDetailModalTabs />
            </div>
            <div class="tab-pane fade" id="voterschemesdetails" role="tabpanel" aria-labelledby="voter-schemes-tab">
              <div v-if="selected_tab == 1">
                <VoterSchemeDetailModalTabs />
              </div>
            </div>
            <div class="tab-pane fade" id="voterhistorydetails" role="tabpanel" aria-labelledby="voter-history-tab">
              <div v-if="selected_tab == 2">
                <VoterHistoryDetailsModalaTabs />
              </div>
            </div>
            <div class="tab-pane fade" id="voterhierachydetails" role="tabpanel" aria-labelledby="voter-hierarchy-tab">
              <div v-if="selected_tab == 5">
                <OrganizationChart />
              </div>
            </div>
            <div class="tab-pane fade" id="voterengagementsdetails" role="tabpanel"
              aria-labelledby="voter-engagements-tab">
              <div v-if="selected_tab == 6">
                <EngagementTotalVoterModal />
              </div>
            </div>
            <div class="tab-pane fade" id="voterinfluencersdetails" role="tabpanel"
              aria-labelledby="voter-influencers-tab">
              <div v-if="selected_tab == 7">
                <MaintenerTotalVoterModal />
              </div>
            </div>
          </div>
          <div v-if="showmodalloader">
            <div class="custom-modal-comman-spinner-loader " style="height:465px">
              <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- next action modal end here -->
  <!-- WhatsApp sms modal start here -->
  <div class="modal-mask" v-if="voterbulkwhatsappmodalsflag">
    <div class="modal-dialog modal-sm modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Bulk WhatsApp</h5>
          <button type="button" class="btn-close" @click="sendBulkWhatsAppModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">Select Citizen <span class="text-danger">*</span></label>
                <div class="custom-group-radio-box-btn">
                  <input type="radio" class="btn-check" v-model="selectbulkvoter" name="bulk-count-option"
                    id="selectcustombulk" autocomplete="off" value="1" />
                  <label class="btn custom-radio-box-btn text-capitalize" for="selectcustombulk">Selected Citizen
                    Only</label>
                  <input type="radio" class="btn-check" v-model="selectbulkvoter" name="bulk-count-option"
                    id="selectallbulk" autocomplete="off" value="2" />
                  <label class="btn custom-radio-box-btn text-capitalize" for="selectallbulk">Select All Citizens</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">Select Template <span class="text-danger">*</span></label>
                <Multiselect v-model="whatsapptemplate" :options="whatsapptemplateList" :searchable="false" label="label"
                  placeholder="Select Template" class="multiselect-custom text-capitalize" :canClear="true"
                  :closeOnSelect="true" />
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label for="formBirthDateInput" class="form-label">Date Variable</label>
                <datepicker v-model="datevariable" inputFormat="dd/MM/yyyy" :lowerLimit="new Date()" :typeable="false"
                  :clearable="false" placeholder="DD/MM/YYYY" class="custom-date-picker form-control" />
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">Campaign Name <span class="text-danger">*</span></label>
                <input type="text" v-model="campaignname" class="form-control" placeholder="Enter Campaign Name"
                  maxlength="30" autocomplete="off" />
              </div>
            </div>
            <!-- <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">Upload Document</label>
                <FileUpload mode="basic" ref="importdocfile" class="custom-upload-btn-ultima" v-model="importdocfile"
                  v-on:change="handleUploadVoterDocument()" />
                <span class="custom-error" v-if="importmsg.file">
                  {{ importmsg.file }}
                </span>
              </div>
            </div>-->
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-end">
            <button type="button" class="btn modal-bulk-next-btn" @click="assignbulkvoterwhatsapp()"
              :disabled="campaignname == '' || campaignname == null || whatsapptemplate == '' || whatsapptemplate == null || selectbulkvoter == ''">
              <span v-if="!bulkwhatsapploader">Submit</span>
              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="bulkwhatsapploader">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- WhatsApp sms modal end here -->
  <!-- bulk call modal start here -->
  <div class="modal-mask" v-if="voterbulkcallmodalsflag">
    <div class="modal-dialog modal-sm modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Bulk Calls</h5>
          <button type="button" class="btn-close" @click="sendBulkCallModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">Upload Mp3 <span class="text-danger">*</span></label>
                <FileUpload mode="basic" ref="bulkuploadcall" class="custom-upload-btn-ultima" v-model="bulkuploadcall"
                  v-on:change="handleUploadtune()" />
                <span class="custom-error" v-if="msgtune.file">
                  {{ msgtune.file }}
                </span>
              </div>
            </div>
            <!-- @change="handleUploadtune()" -->
            <!-- <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">Campaign Name</label>
                <input
                  type="text"
                  v-model="campaignnamecall"
                  class="form-control"
                  placeholder="Enter Campaign Name"
                  maxlength="30"
                  autocomplete="off"
                />
              </div>
            </div> -->
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-end">
            <button type="button" class="btn modal-bulk-next-btn" @click="assignbulkvotercall()"
              :disabled="msgtune.file != '' || bulkuploadcall == null || bulkuploadcall == ''">
              <span v-if="!bulkcallloader">Submit</span>
              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="bulkcallloader">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- bulk call modal end here -->
  <!-- import voters modal start here -->
  <div class="modal-mask" v-if="voterimportmodalsflag">
    <div class="modal-dialog modal-sm modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Import Voters</h5>
          <button type="button" class="btn-close" @click="importVotersModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">Upload CSV File <span class="text-danger">*</span></label>
                <FileUpload mode="basic" ref="importvoterfile" class="custom-upload-btn-ultima" v-model="importvoterfile"
                  v-on:change="handleUploadVoter()" />
                <span class="custom-error" v-if="importmsg.file">
                  {{ importmsg.file }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-end">
            <button type="button" class="btn modal-bulk-next-btn" @click="importvoterfilebtn()"
              :disabled="importmsg.file != '' || importvoterfile == null || importvoterfile == ''">
              <span v-if="!importfileloader">Submit</span>
              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="importfileloader">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- import voters modal end here -->
  <!-- whatsApp History modal start here -->
  <div class="modal-mask" v-if="whatsappmodalsflag">
    <div class="modal-dialog modal-lg modal-dialog-end mt-0 mb-0 me-0 custom-modal-outer modal-dialog-scrollable vh-100">
      <div class="modal-content vh-100">
        <div class="modal-header whatsapp-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            <div class="d-flex align-items-bottom">
              <div class="flex-shrink-0">
                <img v-if="this.whatsappimage == null || this.whatsappimage == ''" src="/assets/images/avatar.png" class="profile-header-avatar rounded-circle" alt="WhatsApp-img" width="35"
                  height="35" />
                  <img v-if="this.whatsappimage != null && this.whatsappimage != '' " :src="this.whatsappimage" class="profile-header-avatar rounded-circle" alt="WhatsApp-img" width="35"
                  height="35" />
              </div>
              <div class="flex-grow-1 ms-3">
                <div class="whatsapp-header-name">{{ this.whatsappname ? this.whatsappname : "N/A" }}</div>
                <div class="whatsapp-header-number">+{{ this.whatsappnumber }}</div>
              </div>
            </div>
          </h5>
          <div class="d-flex justify-content-end align-items-center">
            <button type="button" title="Refresh" class="btn custom-outline-refresh-btn me-3"
              @click="getwhatsappchatlogs()">
              <i class="pi pi-refresh refresh-btn-color"></i>
            </button>
            <button type="button" class="btn-close" @click="whatsappHistoryModalClose()"></button>
          </div>



        </div>
        <div class="modal-body p-0">
          <div class="chatbox-right-outer">
            <div class="chatbox-inner-content">
              <div class="widget-chat p-dialog-content">
                <div id="chatcontainer" class="conversation-container" v-if="!showloadermodal">
                  <div v-for="msg in whatsAppChatHistory" :key="msg">
                    <div class="message received" v-if="msg.lr3 == 1">
                      <div v-if="msg.lr20" class="mb-1">
                        <img @click="whatsappimagepreview(msg)" :src="msg.lr20"
                          style="vertical-align: middle; width:300px"
                          class="whatsapp-Sent-image p-mt-1 cursor-pointer whatsapp-imger-overlay" />
                      </div>
                      <div>
                        {{ msg.lr4 }}
                      </div>
                      <a class="whatsapp-image-cursor" v-if="msg.lr6"><img :src="msg.lr6" style="vertical-align: middle"
                          class="whatsapp-received-image p-mt-1" /></a>
                      <!-- <a class="whatsapp-image-cursor" v-if="msg.ct9"><img :src="msg.ct9"
                                                                style="vertical-align: middle"
                                                                class="whatsapp-received-image p-mt-1" /></a>
                                                        <a class="whatsapp-image-cursor" v-if="msg.ct10"><img
                                                                :src="msg.ct10" style="vertical-align: middle"
                                                                class="whatsapp-received-image p-mt-1" /></a> -->
                      <span class="metadata"><span class="time">{{ format_date_time(msg.datetime)
                      }}</span></span>
                    </div>

                    <div class="message sent" v-if="msg.lr3 == 2">
                      <div v-if="msg.lr20" class="mb-1">
                        <img @click="whatsappimagepreview(msg)" :src="msg.lr20"
                          style="vertical-align: middle; width:300px"
                          class="whatsapp-Sent-image p-mt-1 cursor-pointer whatsapp-imger-overlay" />
                      </div>
                      <div>
                        {{ msg.lr4 }}
                      </div>
                      <a class="whatsapp-image-cursor" v-if="msg.lr6"><img :src="msg.lr6" style="vertical-align: middle"
                          class="whatsapp-Sent-image p-mt-1" /></a>
                      <!-- <a class="whatsapp-image-cursor" v-if="msg.ct9"><img :src="msg.ct9"
                                                                style="vertical-align: middle"
                                                                class="whatsapp-received-image p-mt-1" /></a>
                                                        <a class="whatsapp-image-cursor" v-if="msg.ct10"><img
                                                                :src="msg.ct10" style="vertical-align: middle"
                                                                class="whatsapp-received-image p-mt-1" /></a> -->
                      <span class="metadata"><span class="time">{{ format_date(msg.datetime)
                      }}</span></span>
                    </div>
                  </div>
                </div>
                <div v-if="showloadermodal">
                  <div class="custom-modal-spinner-loader">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer p-0">
          <div class="whatsapp-textbtn-outer">
            <div class="custom-chat-picture text-success" v-if="uploadfilename">
              File attatched
            </div>
            <div class="conversation-compose">
              <textarea class="form-control input-msg custom-chat-group-textarea" :autoResize="true" v-model="chatInput"
                rows="2" placeholder="Type Message here..." />
              <div class="send-btn-outer">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0 ms-2">
                    <label class="fileUpload btn-default upload-document cursor-pointer">
                      <i class="pi pi-paperclip" style="font-size: 1rem"></i>

                      <input type="file" hidden="" name="uploadfile" ref="uploadfile" multiple
                        v-on:change="validateAttachment" />
                    </label>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <button class="send" @click="sendMessage()">
                      <i class="pi pi-send" v-if="!showloaderbtn" style="font-size: 1rem"></i>
                      <div class="
                                            spinner-border
                                            text-light
                                            custom-spinner-loader-btn
                                          " role="status" v-if="showloaderbtn"></div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- whatsApp History modal end here -->
  <div v-if="ImagePreviewDialog" class="modal-mask">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Image Preview</h4>
          <button class="btn-close" @click="whatsappimagepreviewClose"></button>
        </div>
        <div class="modal-body p-0">
          <img :src="previewimg" style="vertical-align: middle; width: 100%;" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, helpers, minLength, maxLength } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ApiService from "../../service/ApiService";
import moment from "moment";

export default {
  inject: ["global"],
  data() {
    return {
      v$: useValidate(),
      rowId: "",
      mobile_number: "",
      AadharTabDetails: "",
      voterTabDetails: "",
      LicenseTabDetails: "",
      PanTabDetails: "",
      ReportChartDetails: "",
      hierarchymodalstatus: false,
      engagementmodalstatus: false,
      maintenermodalstatus: false,
      voterfiltermodalsflag: false,
      voterbulkwhatsappmodalsflag: false,
      voterbulkcallmodalsflag: false,
      voterimportmodalsflag: false,

      lk17: "",
      lk3: "",
      lk23: "",
      lk25: "",
      voterprofessionList: [
        { label: "Student", value: 1 },
        { label: "Self Employed", value: 2 },
        { label: "Salaried", value: 3 },
        { label: "Business", value: 4 },
        { label: "Farmer", value: 5 },
        { label: "Retired", value: 6 },
        { label: "Housewife", value: 7 },
      ],
      lk20: "",
      lk24: "",
      lk22: "",
      lk19: "",
      lk18: "",
      lk27: "",
      lk26: "",

      messageList: [],
      totalRecords: 0,
      page_no: 0,
      selected_tab: 1,
      viewvoterdetailstatus: false,
      pancardnumber: "",
      aadharcardnumber: "",
      votercardnumber: "",
      drivingnumber: "",
      errpan: "",
      aadharerr: "",
      voteriderr: "",
      errdriving: "",
      errmsg: "",
      showloader: false,
      timerAadharInterval: null,
      timerAadharOtpStatus: false,
      aadhar_otp_status: 0,
      showloadermobileotp: false,
      aadharVerifyStatus: false,
      errStatus: false,
      selectedvoter: null,
      voterdivdisplayflag: 0,
      drivingdivdisplayflag: 0,
      pandivdisplayflag: 0,
      aadhardivdisplayflag: 0,
      mobile_otp_status: false,
      mobileVerifyStatus: false,

      whatsapptemplate: "",
      whatsapptemplateList: [],
      selectbulkvoter: 1,
      campaignname: "",
      bulkuploadcall: "",
      campaignnamecall: "",
      loading: false,
      addvotermobile: "",
      addvotershowloader: false,
      aadharotp: "",

      displayMobilePopUp: false,
      timerMobileInterval: null,
      timerMobileStatus: false,
      timerMobileCount: 120,
      mobileotperr: "",

      callstatus: 0,
      bulkwhatsapploader: false,
      bulkcallloader: false,
      msg: [],
      msgtune: [],

      importfileloader: false,
      importmsg: [],
      importvoterfile: "",

      voterdocumentsmodalsflag: false,
      doctemplate: "",
      doctemplateList: [],
      doccampaignname: "",
      importdocfile: "",
      docuploadloader: false,
      datevariable: null,
      VoterSetDefaultSwitchOn: true,
      VoterSetDefaultSwitch: false,
      secVoterTabDetails: [],
      showmodalloader: false,
      whatsappmodalsflag: false,
      showloadermodal: false,
      rowWhatappId: "",
      whatsAppChatHistory: [],
      whatsappusernumber: "",
      whatsappname: "",
      whatsappnumber: "",
      whatsappimage: "",
      ApiLoadingSwitch: false,
      showloaderbtn: false,
      uploadfile: null,
      file1: null,
      uploadfilename: null,
      chatInput: "",
      ImagePreviewDialog: false,
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  validations() {
    return {
      aadharotp: {
        required: helpers.withMessage("Enter OTP", required),
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
    };
  },
  watch: {
    pancardnumber(pancardnumber) {
      this.errmsg = "";
      this.validatePanCardNumber(pancardnumber);
      if (pancardnumber.length == 0) {
        this.errpan = "";
        this.errStatus = false;
      }
    },
    aadharcardnumber(aadharcardnumber) {
      this.errmsg = "";
      this.validateAadharCardNumber(aadharcardnumber);
      if (aadharcardnumber.length == 0) {
        this.aadharerr = "";
        this.errStatus = false;
      }
    },
    votercardnumber(votercardnumber) {
      this.errmsg = "";
      this.validateVoterIdNumber(votercardnumber);
      if (votercardnumber.length == 0) {
        this.voteriderr = "";
        this.errStatus = false;
      }
    },
    drivingnumber(drivingnumber) {
      this.errmsg = "";
      this.validateDrivingNumber(drivingnumber);
      if (drivingnumber.length == 0) {
        this.errdriving = "";
        this.errStatus = false;
      }
    },
  },
  mounted() {
    this.loading = true;
    this.getTemplatesList();

    this.timer = window.setInterval(() => {
      this.getmeranetawhatsappmsgs({
        page_no: this.page_no,
        lk17: this.lk17,
        lk3: this.lk3,
        lk23: this.lk23,
        lk25: this.lk25,
        lk20: this.lk20,
        lk24: this.lk24,
        lk22: this.lk22,
        lk19: this.lk19,
        lk18: this.lk18,
        lk26: this.lk26,
      });
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  methods: {
    calltriggertovoter(ev) {
      this.callstatus = 1;
      this.ApiService.triggercalltomeraneta({ mobile_number: ev }).then(
        (items) => {
          if (items.success === true) {
            this.callstatus = 0;
          } else {
            this.$toast.open({
              message: items.message,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.callstatus = 0;
          }
        }
      );
    },
    fetchvoteriddetails() {
      this.showloader = true;
      let fields = {};
      fields["lk3"] = this.mobile_number;
      fields["voter_id"] = this.votercardnumber;
      this.ApiService.getvoteriddetails(fields).then((items) => {
        if (items.success === true) {
          this.displayMobilePopUp = true;
          this.showloader = false;
          this.viewvoterdetailstatus = false;
        } else {
          this.$toast.open({
            message: items.message,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.showloader = false;
        }
      });
    },
    sendAadharOtp() {
      this.showloader = true;
      if (
        this.aadharcardnumber != "" &&
        this.aadharcardnumber != null &&
        this.aadharcardnumber.length == 12
      ) {
        let fields = {};
        fields["lk3"] = this.mobile_number;
        fields["aadhar_number"] = this.aadharcardnumber;
        this.ApiService.generateaadharotp(fields).then((items) => {
          if (items.success === true) {
            this.displayMobilePopUp = true;
            this.startMobileTimer();
            this.timerMobileStatus = true;
            this.aadharotp = "";
            this.showloader = false;
          } else {
            this.$toast.open({
              message: items.message,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.showloader = false;
          }
        });
      } else {
        this.$toast.open({
          message: "Please enter valid aadhaar number",
          type: "warning",
          duration: 3000,
          position: "top-right",
        });
      }
    },
    verifyAadharOtp() {
      this.showloader = true;
      let fields = {};
      fields["lk3"] = this.mobile_number;
      fields["otp"] = this.aadharotp;
      this.ApiService.getaadharcarddetails(fields).then((items) => {
        if (items.success === true) {
          this.showloader = false;
          this.$toast.open({
            message: items.message,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.viewvoterdetailstatus = false;
        } else {
          this.showloader = false;
          this.mobileotperr = items.message;
        }
      });
    },
    startMobileTimer() {
      this.timerMobileStatus = true;
      if (this.timerMobileCount > 0) {
        setTimeout(() => {
          this.timerMobileCount--;
          this.startMobileTimer();
        }, 1000);
        const minutes = Math.floor(this.timerMobileCount / 60);
        let seconds = this.timerMobileCount % 60;
        if (seconds < 10) {
          this.timerMobileInterval = `0${minutes}:0${seconds}`;
        } else {
          this.timerMobileInterval = `0${minutes}:${seconds}`;
        }
      } else {
        this.timerMobileCount = 120;
        this.timerMobileStatus = false;
      }
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    getpancarddetails() {
      // this.showloader = true;
    },
    getdrivingdetails() {
      // this.showloader = true;
    },
    mobileotp(mobileval) {
      if (mobileval.length == 4) {
        this.verifyMobileOtp();
      }
    },
    verifyMobileOtp() {
      this.v$.aadharotp.$validate();
      let fields = {};
      fields["cb9_otp"] = this.aadharotp;
      if (!this.v$.aadharotp.$error) {
        this.showloadermobileotp = true;
        this.ApiService.verifymobileotp(fields).then((items) => {
          if (items.status === 200) {
            this.mobile_otp_status = 1;
            this.showloadermobileotp = false;
            this.timerMobileCount = 0;
            this.$toast.open({
              message: items.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.displayRegBtn = true;
            this.mobileVerifyStatus = true;
            if (
              this.mobileVerifyStatus == true &&
              this.emailVerifyStatus == true
            ) {
              clearInterval(this.timer);
            }
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
          } else {
            this.showloadermobileotp = false;
            this.$toast.open({
              message: items.message,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          }
        });
      }
    },
    redirectfn() {
      this.$router.push("/citizen/report");
    },
    getvotertabdetails() {
      this.getvoterdetails();
      this.getsecvoterdetails();
    },
    getvoterdetails() {
      this.ApiService.getvoterdetails({ lk1: this.rowId }).then((data) => {
        if (data.success == true) {
          this.voterTabDetails = data.records;
          this.voterdivdisplayflag = 1;
        } else {
          this.voterdivdisplayflag = 0;
        }
      });
    },
    getsecvoterdetails() {
      this.ApiService.getsecvoterdetails({ lk1: this.rowId }).then((data) => {
        if (data.success == true) {
          this.secVoterTabDetails = data.records;
          this.voterdivdisplayflag = 1;
        } else {
          this.secVoterTabDetails = 0;
        }
      });
    },
    getlicensedetails() {
      this.ApiService.getlicensedetails({ lk1: this.rowId }).then((data) => {
        if (data.success == true) {
          this.LicenseTabDetails = data.records;
          this.drivingdivdisplayflag = 1;
        } else {
          this.drivingdivdisplayflag = 0;
        }
      });
    },
    getaadhardetails() {
      this.ApiService.getaadhardetails({ lk1: this.rowId }).then((data) => {
        if (data.success == true) {
          this.AadharTabDetails = data.records;
          this.aadhardivdisplayflag = 1;
          this.showmodalloader = false;
        } else {
          this.aadhardivdisplayflag = 0;
          this.showmodalloader = false;
        }
      });
    },
    getpandetails() {
      this.ApiService.getpandetails({ lk1: this.rowId }).then((data) => {
        if (data.success == true) {
          this.PanTabDetails = data.records;
          this.pandivdisplayflag = 1;
        } else {
          this.pandivdisplayflag = 0;
        }
      });
    },
    selectedtab(e) {
      this.selected_tab = e;
    },
    customerdetailModalOpen(e, mobile_number) {
      this.showmodalloader = true;
      this.rowId = e;
      this.mobile_number = mobile_number;
      this.viewvoterdetailstatus = true;
      this.getaadhardetails();
      this.votercardnumber = "";
      this.aadharcardnumber = "";
      this.aadharotp = "";
      this.pancardnumber = "";
      this.drivingnumber = "";
    },

    voterDetailModalClose() {
      this.viewvoterdetailstatus = false;
    },
    voterFilterModalOpen() {
      this.voterfiltermodalsflag = true;
    },
    voterFilterModalClose() {
      this.voterfiltermodalsflag = false;
    },
    sendBulkWhatsAppModal() {
      this.voterbulkwhatsappmodalsflag = true;
      this.getTemplatesList();
    },
    sendBulkWhatsAppModalClose() {
      this.voterbulkwhatsappmodalsflag = false;
      this.whatsapptemplate = "";
      this.campaignname = "";
    },
    sendDocumentModal() {
      this.voterdocumentsmodalsflag = true;
      this.getTemplatesList();
    },
    sendDocumentModalClose() {
      this.voterdocumentsmodalsflag = false;
    },
    sendBulkCallModal() {
      this.voterbulkcallmodalsflag = true;
    },
    sendBulkCallModalClose() {
      this.voterbulkcallmodalsflag = false;
    },
    importVotersModal() {
      this.voterimportmodalsflag = true;
    },
    importVotersModalClose() {
      this.voterimportmodalsflag = false;
    },
    btnFilterSearch(lk17, lk3, lk23, lk25, lk20, lk24, lk22, lk19, lk18, lk26) {
      this.voterfiltermodalsflag = false;
      this.lk17 = lk17;
      this.lk3 = lk3;
      this.lk23 = lk23;
      this.lk25 = lk25;
      this.lk20 = lk20;
      this.lk24 = lk24;
      this.lk22 = lk22;
      this.lk19 = lk19;
      this.lk18 = lk18;
      this.lk26 = lk26;
      this.getmeranetawhatsappmsgs({
        page_no: this.page_no,
        lk17: this.lk17,
        lk3: this.lk3,
        lk23: this.lk23,
        lk25: this.lk25,
        lk20: this.lk20,
        lk24: this.lk24,
        lk22: this.lk22,
        lk19: this.lk19,
        lk18: this.lk18,
        lk26: this.lk26,
      });
    },
    resetVoterFilter() {
      this.lk17 = "";
      this.lk3 = "";
      this.lk23 = "";
      this.lk25 = "";
      this.lk20 = "";
      this.lk24 = "";
      this.lk22 = "";
      this.lk19 = "";
      this.lk18 = "";
      this.lk26 = "";
      this.getmeranetawhatsappmsgs();
      this.voterfiltermodalsflag = false;
    },
    getmeranetawhatsappmsgs(e) {
      this.ApiService.getmeranetawhatsappmsgs(e).then((data) => {
        if (data.success == true) {
          this.messageList = data.records;
          this.totalRecords = data.totalcount;
          this.loading = false;
        } else {
          this.messageList = null;
          this.totalRecords = 0;
          this.loading = false;
        }
      });
    },

    hierarchymodalopen() {
      this.hierarchymodalstatus = true;
    },
    closerhierarchymodal() {
      this.hierarchymodalstatus = false;
    },
    voterengagementmodalopen() {
      this.engagementmodalstatus = true;
    },
    closevoterengagementmodal() {
      this.engagementmodalstatus = false;
    },
    votermaintenermodalopen() {
      this.maintenermodalstatus = true;
    },
    closevotermaintenermodal() {
      this.maintenermodalstatus = false;
    },
    isLetterWithSpace(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z\s.]+$/.test(char)) return true;
      else e.preventDefault();
    },

    changePage(event) {
      this.page_no = event.page;
      this.getmeranetawhatsappmsgs({ page_no: this.page_no });
    },
    validatePanCardNumber(val) {
      this.errStatus = false;
      if (!this.validPAN(val)) {
        this.errpan = "Please enter valid pan number";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.errpan = "";
        return true;
      }
    },

    validPAN: function (e) {
      var re = /([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}$/;
      return re.test(e);
    },
    validateAadharCardNumber(e) {
      this.errStatus = false;
      if (!this.validAadharCard(e)) {
        this.aadharerr = "Please enter valid aadhaar number";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.aadharerr = "";
        return true;
      }
    },

    validAadharCard: function (e) {
      var re = /([0-9]){12}$/;
      return re.test(e);
    },

    validateVoterIdNumber(e) {
      this.errStatus = false;
      if (!this.validVoterId(e)) {
        this.voteriderr = "Please enter valid voter id";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.voteriderr = "";
        return true;
      }
    },

    validVoterId: function (e) {
      var re = /([A-Za-z]){3}([0-9]){7}$/;
      return re.test(e);
    },

    validateDrivingNumber(e) {
      this.errStatus = false;
      if (!this.validDrivingNo(e)) {
        this.errdriving = "Please enter valid driving licence no";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.errdriving = "";
        return true;
      }
    },

    validDrivingNo: function (e) {
      var re = /([A-Za-z]){2}([0-9]){13}$/;
      return re.test(e);
    },
    addNewVoter() {
      this.addvotershowloader = true;
      let fields = {};
      fields["mobile_number"] = this.addvotermobile;
      this.ApiService.addvoter(fields).then((items) => {
        if (items.success === true) {
          this.addvotershowloader = false;
          this.addvotermobile = "";
        } else {
          this.$toast.open({
            message: items.message,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.addvotershowloader = false;
        }
      });
    },
    assignbulkvoterwhatsapp() {
      this.bulkwhatsapploader = true;
      // var formData = new FormData();
      // this.selectedvoter.map(function (item) {
      //   formData.append("voters[]", item.lk1);
      // });
      // formData.append("template_id", this.whatsapptemplate);
      // formData.append("campaign_name", this.campaignname);
      // formData.append("document", this.importdocfile);

      let fields = {};
      var votersdata = [];
      this.selectedvoter.map(function (item) {
        votersdata.push(item.lk1);
      });
      fields["campaign_type"] = this.selectbulkvoter;
      fields["template_id"] = this.whatsapptemplate;
      fields["campaign_name"] = this.campaignname;
      fields["datevariable"] = this.datevariable;
      fields["voters"] = votersdata;
      this.ApiService.savecampaign(fields).then((data) => {
        if (data.success == true) {
          this.bulkwhatsapploader = false;
          this.voterbulkwhatsappmodalsflag = false;
          this.whatsapptemplate = "";
          this.campaignname = "";
          this.importdocfile = "";
        }
      });
    },
    assignbulkvotercall() {
      this.bulkcallloader = true;
      var formData = new FormData();
      this.selectedvoter.map(function (item) {
        formData.append("mobile_numbers[]", item.lk3);
      });
      formData.append("mp3_file", this.bulkuploadcall);
      this.ApiService.bulkcall(formData).then((data) => {
        if (data.success == true) {
          this.bulkcallloader = false;
          this.voterbulkcallmodalsflag = false;
        }
      });
    },
    handleUploadtune() {
      this.bulkuploadcall = this.$refs.bulkuploadcall.files[0];
      var file = this.$refs.bulkuploadcall.files[0];
      let allowedExtensions = /(\.mp3)$/i;
      if (!file) {
        this.msgtune["file"] = "Please upload mp3 tune.";
      } else if (!file.size > 5048) {
        this.msgtune["file"] = "File size cannot exceed more than 5MB";
      } else if (!file.name.match(allowedExtensions)) {
        this.msgtune["file"] = "Invalid file type";
      } else {
        this.msgtune["file"] = "";
      }
    },
    importvoterfilebtn() {
      this.importfileloader = true;
      var formData = new FormData();
      formData.append("importfile", this.importvoterfile);
      this.ApiService.importvoters(formData).then((data) => {
        if (data.success == true) {
          this.importfileloader = false;
          this.voterimportmodalsflag = false;
          this.importvoterfile = '';
        }
      });
    },
    handleUploadVoter() {
      this.importvoterfile = this.$refs.importvoterfile.files[0];
      var file = this.$refs.importvoterfile.files[0];
      let allowedExtensions = /(\.csv)$/i;
      if (!file) {
        this.importmsg["file"] = "Please upload CSV excel file.";
      } else if (!file.size > 2048) {
        this.importmsg["file"] = "File size cannot exceed more than 2MB";
      } else if (!file.name.match(allowedExtensions)) {
        this.importmsg["file"] = "Invalid file type";
      } else {
        this.importmsg["file"] = "";
      }
    },
    handleUploadVoterDocument() {
      this.importdocfile = this.$refs.importdocfile.files[0];
      var file = this.$refs.importdocfile.files[0];
      // let allowedExtensions = /(\.pdf)$/i;
      if (!file) {
        this.importmsg["file"] = "Please upload document.";
      } else if (!file.size > 2048) {
        this.importmsg["file"] = "File size cannot exceed more than 2MB";
      } else {
        this.importmsg["file"] = "";
      }
    },
    getTemplatesList() {
      this.ApiService.campaigntemplates().then((data) => {
        if (data.success == true) {
          this.whatsapptemplateList = data.records;
          this.doctemplateList = data.records;
        }
      });
    },
    format_date_time(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm A");
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    switchVoterCardStatus(e) {
      this.ApiService.setprimaryvoterid({ ab1: e.ab1, ab2: e.ab2 }).then((data) => {
        if (data.success == true) {
          this.getvotertabdetails();
          this.VoterSetDefaultSwitch = false;
        }
      });
    },
    whatsappHistoryModalOpen(e, username, usernumber, userimage) {
      this.rowWhatappId = e;
      this.whatsappname = username;
      this.whatsappnumber = usernumber;
      this.whatsappimage = userimage;
      this.whatsappmodalsflag = true;
      this.getwhatsappchatlogs();
      this.timer31 = window.setInterval(() => {
        this.getwhatsappchatlogstimer();
      }, 5000);
    },
    getwhatsappchatlogs() {
      this.showloadermodal = true;
      this.chatInput = "";
      this.file1 = "";
      this.uploadfilename = "";
      this.ApiService.getwhatsappchatlogs({ lr13: this.rowWhatappId }).then((data) => {
        if (data.success == true) {
          this.showloadermodal = false;
          this.whatsAppChatHistory = data.records;
        }
      });
      this.scrollmessage();
    },
    getwhatsappchatlogstimer() {
      this.ApiService.getwhatsappchatlogs({ lr13: this.rowWhatappId }).then((data) => {
        if (data.success == true) {
          this.whatsAppChatHistory = data.records;
        }
      });
    },
    whatsappHistoryModalClose() {
      this.whatsappmodalsflag = false;
      clearInterval(this.timer31);
    },
    sendMessage() {
            const formData = new FormData();
            formData.append("lr5", this.whatsappnumber);
            formData.append("lr13", this.rowWhatappId);
            formData.append("lr14", this.whatsappname);
            if (this.chatInput) {
                formData.append("message", this.chatInput);
            }
            if (this.file1) {
                formData.append("media", this.file1);
            }
            this.showloaderbtn = true;
            this.ApiService.sendwhatsappmessage(formData).then((data) => {
                if (data.success == true) {
                    this.showloaderbtn = false;
                    this.chatInput = "";
                    this.file1 = "";
                    this.uploadfilename = "";
                    this.getwhatsappchatlogs();
                    this.scrollmessage();
                } else {
                    this.showloaderbtn = false;
                    this.chatInput = "";
                    this.file1 = "";
                    this.uploadfilename = "";
                    this.getwhatsappchatlogs();
                }
            }
            );
        },
        validateAttachment(e) {
            var fileData = e.target.files[0];
            this.uploadfilename = fileData.name;
            if (this.$refs.uploadfile.files.length > 3) {
                this.$toast.open({
                    message: "Maximum 3 files are allowed",
                    type: "success",
                    duration: 3000,
                    position: "top-right",
                });
            } else {
                let len = this.$refs.uploadfile.files.length;
                if (len === 1) {
                    this.file1 = this.$refs.uploadfile.files[0];
                } else if (len === 2) {
                    this.file1 = this.$refs.uploadfile.files[0];
                    this.file2 = this.$refs.uploadfile.files[1];
                } else if (len === 3) {
                    this.file1 = this.$refs.uploadfile.files[0];
                    this.file2 = this.$refs.uploadfile.files[1];
                    this.file3 = this.$refs.uploadfile.files[2];
                }
            }
        },
        scrollmessage() {
            setTimeout(() => {
                var messageBody = document.querySelector('#chatcontainer');
                messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
            }, 500);
        },
        whatsappimagepreview(data) {
            this.previewimg = data.lr20;
            this.ImagePreviewDialog = true;
        },
        whatsappimagepreviewClose() {
            this.ImagePreviewDialog = false;
        },
        backToSchemeListRedirect() {
            this.$router.push("/schemes/total");
        }
  },
};
</script>
<style scoped>
.company-header-box-outer {
  padding: 9px 16px;
  border: 1px solid #e0e5ed;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 0;
}

.company-legal-name {
  font-family: "AcuminPro-SemiBold";
  letter-spacing: 0px;
  color: #2b2e38;
  font-size: 16px;
  line-height: 20px;
  white-space: break-spaces;
}

.company-detail-box-outer {
  padding: 16px 16px 0;
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
  width: 100%;
}

.branch-detail-box-outer {
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
}

.branch-detail-box-outer .branch-row-box {
  padding: 16px;
  border-bottom: 1px solid #e0e5ed;
}

.branch-detail-box-outer .branch-row-box:last-child {
  border-bottom: 0;
}

.customer-label-group {
  margin-bottom: 20px;
}

.form-label-header {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #2b2e38;
  line-height: 16px;
}

.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}

.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
  word-break: break-word;
}

.company-section-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  /* height: 514px; */
}

.company-section-outer::-webkit-scrollbar {
  display: block;
}

.company-section-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.company-section-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}

.custom-outline-whatsapp-btn {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 5px 6px;
  line-height: 10px;
}

.custom-outline-whatsapp-btn .whatsapp-color {
  color: #2ca270;
  font-size: 18px;
}

.custom-outline-call-btn {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 7.5px;
  line-height: 10px;
}

.custom-outline-call-btn .call-btn-color {
  color: #2ca270;
  font-size: 13px;
}

.custom-outline-ellipsis-btn {
  padding: 0;
  border: 0;
}

.text-star-blank {
  color: #c1c5c9;
}

.voter-report-section {
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 12px;
  border: 1px solid #e0e5ed;
  border-radius: 4px 4px 0px 0px;
  border-top: 0;
}

.feedback-chart-outer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
}

.feedback-chart-outer .chart-label {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #4a5463;
  text-align: left;
  line-height: 16px;
  padding: 16px;
}

.modal-bulk-next-btn.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 35px;
  font-family: "AcuminPro-Regular";
}

.modal-bulk-next-btn:focus {
  box-shadow: none;
}

.from-switch-label {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
  word-break: break-word;
  margin-right: 10px;
}

/* whatsApp history modal css start here  */
.chatbox-right-outer {
    max-width: 752px;
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
    /* height: 550px; */
    padding-left: 0px;
    border-radius: 0.3rem;
    height: 100%;
}

.chatbox-left-outer .chatbox-inner-content .applicants-name {
    text-align: left;
    letter-spacing: 0px;
    color: #2a2a2a;
    opacity: 1;
    font-size: 14px;
    font-family: "OpenSans-SemiBold";
    margin-left: 12px;
}

.chatbox-left-outer .chatbox-inner-content .applicants-timer {
    text-align: left;
    letter-spacing: 0px;
    color: #3a3a3a;
    opacity: 1;
    font-size: 12px;
    font-family: "AcuminPro-Regular";
}

.chatbox-left-outer .chatbox-inner-content .applicants-content {
    text-align: left;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    margin-top: 10px;
    font-size: 12px;
    font-family: "AcuminPro-Regular";
}



.chatbox-right-outer .chatbox-inner-content {
    /* padding: 20px 0px 0px; */
    opacity: 1;
    height: 100%;
}
.widget-chat,
.employer-profile-scroll-height {
    position: relative;
    min-height: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.p-dialog-header {
    height: 55px;
    background: #005e54;
    color: #fff;
    padding: 0 8px;
    font-size: 24px;
    position: relative;
    z-index: 1;
}

.p-dialog-header .avatar {
    margin: 0 0 0 5px;
    width: 36px;
    height: 36px;
}

.p-dialog-header .avatar img {
    border-radius: 50%;
    display: block;
    width: 100%;
}

.p-dialog-header .name {
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0.3px;
    margin: 0 0 0 8px;
    overflow: hidden;
    text-transform: capitalize;
}

.p-dialog-header .status {
    display: block;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0;
}

.p-dialog-header .p-dialog-header-icon,
.p-dialog-header .p-dialog-header-icon:hover {
    color: #ffffff;
}

.p-dialog-content {
    position: relative;
    background: #efe7dd url("/assets/images/whatsapp-bg-img.jpg") repeat;
    z-index: 0;
    padding: 0;
    overflow: auto;
    height: 100%;
    overflow-y: hidden;
}

.conversation-container {
    /* overflow-x: hidden;
    overflow-y: auto;
    padding: 20px; */

    height: calc(100% - 68px);
    /* box-shadow: inset 0 10px 10px -10px #000000; */
    overflow-x: hidden;
    padding: 0 16px;
    margin-bottom: 5px;
    height: 100%;
}

.message {
    color: #000;
    clear: both;
    line-height: 18px;
    font-size: 15px;
    padding: 8px;
    position: relative;
    margin: 8px 0;
    max-width: 50%;
    word-wrap: break-word;
}

.message.sent {
    background: #d2f1e1;
    border-radius: 5px 0px 5px 5px;
    float: right;
    text-align: left;
    letter-spacing: 0px;
    color: #4a4a4a;
    font-size: 13px;
    opacity: 1;
    font-family: "AcuminPro-Regular";
}

.message.received {
    background: #cfddf5;
    border-radius: 0px 5px 5px 5px;
    float: left;
    letter-spacing: 0px;
    color: #4a4a4a;
    opacity: 1;
    font-family: "AcuminPro-Regular";
    font-size: 13px;
}

.metadata {
    display: inline-block;
    float: right;
    padding: 0 0 0 7px;
    position: relative;
    bottom: -4px;
}

.whatsapp-header {
    background: #005e54 0% 0% no-repeat padding-box;
}

.whatsapp-header .modal-title {
    color: #ffffff;
}

.whatsapp-header .btn-close {
    background-color: #ffffff;
}

.whatsapp-header-name {
    font-size: 17px;
}

.whatsapp-header-number {
    font-size: 12px;
}

.conversation-container::-webkit-scrollbar,
.p-dialog-content::-webkit-scrollbar,
.input-msg::-webkit-scrollbar {
    transition: all 0.5s !important;
    width: 3px !important;
    height: 3px !important;
    z-index: 10 !important;
}

.conversation-container::-webkit-scrollbar-thumb,
.p-dialog-content::-webkit-scrollbar-thumb,
.input-msg::-webkit-scrollbar-thumb {
    background: #7f7777 !important;
}

.metadata .time {
    color: rgba(0, 0, 0, 0.45);
    font-size: 11px;
    display: inline-block;
}

.message.sent:after {
    border-width: 0px 0 10px 10px;
    border-color: transparent transparent transparent #d2f1e1;
    top: 0;
    right: -10px;
}

.message.received:after {
    border-width: 0px 10px 10px 0;
    border-color: transparent #cfddf5 transparent transparent;
    top: 0;
    left: -10px;
}

.message:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
}

.whatsapp-received-image {
    max-width: 150px;
    width: 100%;
    display: block;
    cursor: pointer;
}

.whatsapp-image-cursor {
    opacity: 1;
    transition: 0.5s ease;
    backface-visibility: hidden;
}

.whatsapp-image-cursor:hover {
    opacity: 0.5;
    display: block;
}

.conversation-compose {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    overflow: hidden;
    height: 50px;
    width: 100%;
    z-index: 2;
}

.conversation-compose .input-msg {
    border: 0;
    flex: 1 1 auto;
    font-size: 16px;
    margin: 0;
    outline: none;
    min-width: 50px;
    height: 50px !important;
    overflow: auto !important;
    border-radius: 5px 0 0 5px;
}

.conversation-compose .input-msg,
.conversation-compose .attachment {
    background: #fff;
    height: 100%;
}

.conversation-compose .send-btn-outer {
    background: transparent;
    border: 0;
    flex: 0 0 auto;
    margin-left: 8px;
    padding: 0;
    position: relative;
    outline: none;
}

.send-btn-outer .pi.pi-send {
    font-size: 1.5rem;
    transform: rotate(45deg);
}

.conversation-compose .send {
    background: #008a7c;
    border-radius: 50%;
    color: #fff;
    position: relative;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0px;
    margin-bottom: 2px;
    border: 0;
}

/* .conversation-compose .send:hover {
    border-color: #86b7fe;
} */

.conversation-compose .input-msg:hover {
    background-image: none;
}

.conversation-compose .input-msg:focus {
    background-color: #ffffff;
}

.whatsapp-textbtn-outer {
    padding-left: 8px;
    padding-top: 10px;
    padding-right: 8px;
    background-color: #d3d3d3;
    padding-bottom: 9px;
    border-radius: 0 0 0.3rem 0.3rem;
    width: 100%;
    margin: 0;
}

.conversation-compose .attachment {
    flex: 0 0 auto;
    border-radius: 0 0 5px 0;
    text-align: center;
    position: relative;
}

.conversation-compose .attachment:after {
    border-width: 0px 0 10px 10px;
    border-color: transparent transparent transparent #fff;
    border-style: solid;
    position: absolute;
    width: 0;
    height: 0;
    content: "";
    top: 0;
    right: -10px;
}

.conversation-compose .attachment .p-fileupload.p-fileupload-basic {
    transform: translate(-50%, -50%);
    position: relative;
    top: 50%;
    left: 50%;
}

.conversation-compose .attachment .p-fileupload-basic .p-fileupload-choose {
    display: block;
    background-color: transparent;
}

.conversation-compose .attachment .pi {
    display: block;
    color: #7d8488;
    font-size: 24px;
    margin-right: 0px;
}

.conversation-compose .attachment .p-button-label {
    display: none;
}

.conversation-compose .attachment .p-ink {
    display: none;
}

.whatsapp-popup-loader .pi {
    font-size: 2rem !important;
}

.form-control.input-msg.custom-chat-group-textarea {
    text-align: left;
    letter-spacing: 0px;
    color: #4a4a4a;
    opacity: 1;
    font-size: 14px;
    font-family: "AcuminPro-Regular";
    padding: 10px 10px;
    resize: none;
}

.form-control.input-msg.custom-chat-group-textarea::placeholder {
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    font-size: 14px;
    font-family: "AcuminPro-Regular";
    text-align: start;
}

.form-control.input-msg.custom-chat-group-textarea:focus {
    border: 1px solid #86b7fe;
    box-shadow: none;
}

.whatsapp-imger-overlay:hover {
    opacity: 0.7;
}

.custom-outline-refresh-btn {
    padding: 6.5px;
    line-height: 10px;
    opacity: .5;
    background-color: #ffffff;
    border-radius: 0.25rem;
}

.custom-outline-refresh-btn:hover{
    opacity: .75;
}

.custom-outline-refresh-btn .refresh-btn-color {
    color: #000000;
    font-size: 13px;
}
@media only screen and (min-width: 1024px) {
    .chatbox-right-outer {
        max-width: 100% !important;
    }
}
</style>